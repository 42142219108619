

import Card from 'react-bootstrap/Card';






function MottoKartya() {
    return (
        <Card>
            <Card.Body className={"card-body-cim"}>
                <Card.Text className="text-center_cim">
                    {/*"A jó élet folyamat, nem pedig létállapot.
                        Ez egy irány, nem pedig cél."*/}
                    <h1>Nagyító Tanácsadás</h1>
                    <h2>Gyarmathy Dorottya</h2>
                </Card.Text>
            </Card.Body>

        </Card>
    );
}

export default MottoKartya;