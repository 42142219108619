import Card from 'react-bootstrap/Card';
import React from "react";
import Rolam from './Rolam';
import EgyeztessunkIdopontot from './EgyeztessunkIdopontot'


function TanacsadasKartya() {
    return (
        <Card className="text-center">
            <Card.Body style={{
                alignItems: "center",
            }}>
                <Card.Img classname={"portreimage"}  src={require("./images/portre.webp")} style={{
                    alignItems: "center",
                    maxHeight: "30vh",
                    width: "auto",
                    objectFit: "contain",

                }}/>
                <Card.Text>
                    Figyelem, amely segíthet.
                    <br/>
                    <br/>
                    <div style={{
                        alignItems: "center",

                    }}>
                        <Rolam/>
                    </div>
                    <br/>
                    Tanácsadás személyesen vagy online
                    <br/>
                    1143 Budapest Stefánia út 61.
                    <br/>
                    8000Ft / 60 perc

                </Card.Text>

            </Card.Body>
            <EgyeztessunkIdopontot/>
        </Card>
    );
}

export default TanacsadasKartya;