

import Card from 'react-bootstrap/Card';
import iroda from './images/iroda.webp';
import React from "react";
import EgyeztessunkIdopontot from './EgyeztessunkIdopontot'

function TanacsadasKartya() {
    return (
        <Card className="text-center" >

            <Card.Body>

                <Card.Text className={"tanacsadasSzoveg"}>
                        <p>Ha úgy érzed, hogy mostanában nem vagy önmagad, esetleg néha rád tör a szorongás...

                        Ha felmerül benned, hogy nem azt az életet éled, amit szeretnél, és úgy érzed elakadtál...


                        Ha bármennyire szeretnél tovább lépni, változtatni, de valamiért nem megy...


                        Ha történt az életedben valami nagy változás, amit nehéznek vagy fájdalmasnak élsz meg...


                            Ha vannak olyan helyzetek, amiket szeretnél jobban, hatékonyabban kezelni...</p>

                    <p>...akkor a  tanácsadás alkalmával a teljes figyelmemet ajánlom, és azt, hogy minden tudásommal megpróbálok segíteni abban,
                        hogy megoldódjon a problémád és visszatalálj önmagadhoz.</p>
                </Card.Text>

                <EgyeztessunkIdopontot/>

                <Card.Img variant="top" src={require("./images/iroda.webp")} style={{
                    maxWidth:"100%",
                    width: "auto",
                    objectFit: "cover",
                    marginBottom: "10px",
                    borderRadius:"10px"
                }}/>

            </Card.Body>

        </Card>
    );
}

export default TanacsadasKartya;