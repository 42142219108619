import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <button
            type="button"
            className={"bemutatkozas_gomb"}
            onClick={decoratedOnClick}>
            {children}
        </button>
    );
}

function Example() {
    return (
        <Accordion defaultActiveKey="1">
            <div>
                <div>
                    <CustomToggle eventKey="0">Bemutatkozás</CustomToggle>
                </div>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <p className={"card-body_bemutatkozas"}>
                            Gyarmathy Dorottya vagyok pedagógus, viselkedés elemző és pszichológus jelölt.
                        2004-ben végeztem, mint tanító és zenepedagógus Kaposváron. 2021-ben szereztem
                        diplomát a Károli Gáspár Református Egyetem pszichológia szakán, mint
                        viselkedéselemző. Jelenleg a Szegedi Tudományegyetem pszichológia mesterképzésén
                        veszek részt.
                        Mindig is érdekeltek az emberek élettörténetei és a fejlődésük alakulása. Az
                        iskolában a kisiskolások ismereti tudásának fejlesztése mellett mindig sokat
                        foglalkoztatott a személyiség alakulása, és fejlesztése. Először a zene által
                        kerestem a lehetőségeket, hogy annak segítségével, hogyan lehet kibontakoztatni a
                        személyiséget, majd rátaláltam a pszichológiára, ami segített megérteni, hogy
                        bizonyos esetekben miért viselkedünk úgy ahogy...
                        Eddigi tapasztalataimat és tudásomat szeretném felhasználni arra, hogy egy-egy nehéz
                        helyzeten át tudjam kísérni azt, akinek támaszra, segítségre van szüksége arra az
                        időre, amíg vissza nem nyeri teljes életerejét.</p>
                    </Card.Body>
                </Accordion.Collapse>
            </div>

        </Accordion>
    );
}

export default Example;