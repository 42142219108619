

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Kapcsolat from "./Kapcsolat.js"
import MottoCards from './MottoCard.js'
import Tanacsadas from  './Tanacsadas'
import Bemutatkozas from './Bemutatkozas'


function JustifiedExample() {
    return (
        <Tabs
            style={{
                maxHeight:"100%",
                width:"auto",
                color:"green"
            }}
            defaultActiveKey="Bemutatkozas"
            id="fomenu"
            className="mb-3"
            justify
        >
            <Tab eventKey="Bemutatkozas" title="Bemutatkozás">
                <Bemutatkozas/>
            </Tab>

            <Tab eventKey="Tanacsadas" title="Tanácsadás">
                <Tanacsadas/>
            </Tab>
            <Tab eventKey="Kapcsolat" title="Kapcsolat">
                <Kapcsolat/>
            </Tab>


        </Tabs>
    );
}

export default JustifiedExample;