import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
//import Kapcsolat
//import Tanacsadas
//import MottoCards
import Tabs from './Tabs.js'
import Cim from './Cim'


function App() {
  return (
    <div className={"App"} style={{
        height: '80vh',
        padding:'20px',
        boxSizing: "border-box",
        padding: "2rem"

    }}>
      <header >
          <Cim/>

      </header>
        <Tabs/>
    </div>
  );
}

export default App;
