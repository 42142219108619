import React from "react";




function EgyeztessunkIdopontot() {
    return (
        <div

        className={"egyeztessunk_idopontot"}
        >Egyeztessünk időpontot: <br/>
            <a href="tel:06202110491" className="btn btn-outline-success">
            06202110491
        </a></div>
    );
}

export default EgyeztessunkIdopontot;