

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import iroda from './images/iroda.webp';
import ListGroup from 'react-bootstrap/ListGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React, {useState} from 'react';
import EgyeztessunkIdopontot from './EgyeztessunkIdopontot'


function Kapcsolatkartya() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (

        <div>



            <Card
                style={{
                    //height: '10vh';
                    maxHeight: "80vh",
                    width: "auto",
                    //height: "auto",
                    alignItems: 'center',
                }}
            >


                <Card.Body className="text-center" style={{

                    alignItems: 'center',
                    flex: "1",
                }}>
                    <Card.Title>Kapcsolat</Card.Title>
                    <ListGroup variant="flush">
                        <ListGroup.Item>1143 Budapest Stefánia út 61.</ListGroup.Item>
                        <ListGroup.Item>E-mail:
                            gyarmathydorottya@nagyitotanacsadas.hu</ListGroup.Item>
                        <ListGroup.Item>Telefon: 06202110491</ListGroup.Item>
                        <ListGroup.Item>Gyarmathy Dorottya egyéni vállalkozó</ListGroup.Item>
                        <ListGroup.Item>Adószám: 57615985-1-42</ListGroup.Item>
                    </ListGroup>
                    <div style={{
                        borderBottom: '1px solid',


                    }}>

                    </div>
                    <EgyeztessunkIdopontot/>
                </Card.Body>
            </Card>

        </div>
    );
}

export default Kapcsolatkartya;